export default {
  "contact.can_contact_me_email": "You can contact me by email",
  "contact.desc": "Any questions ?  So don't hesitate !",
  "contact.email_copy_in_clipboard": "Email copied to clipboard",
  "contact.error_email_copy_in_clipboard": "The email could not be copied to the clipboard.",
  "contact.or_find_me": "Or find me on the following networks",
  "experiences.desc": "A junior but atypical course to get where I am today",
  "experiences.see_website": "See the company's website",
  "experiences.step.b2l.desc": "Realization and development of web applications for car distribution.",
  "experiences.step.b2l.hiring": "Hired following the internship in July 2019.",
  "experiences.step.b2l.internship": "Internship at the end of the Professional Licence year.",
  "experiences.step.b2l.since": "Since {date}",
  "experiences.step.b2l.team": "Integrated in a team of about ten people.",
  "experiences.step.b2l.web_app_dev": "Web application developer",
  "experiences.step.datakit.desc": "Redesign of an internal web tool for customer follow-up.",
  "experiences.step.datakit.intern_web_dev": "Intern in web development",
  "experiences.step.datakit.internship": "End of year DUT internship.",
  "experiences.step.datakit.work": "Work in autonomy.",
  "experiences.step.interim.area": "Mâconnaise region",
  "experiences.step.interim.desc": "Handler, cashier salesman, wine-cellar employee, metal worker, etc.",
  "experiences.step.interim.title": "Interim work",
  "experiences.step.jobeline.desc": "Wine-growing works",
  "experiences.step.super_u.desc": "Shelving",
  "experiences.summer": "Summer",
  "formation.desc": "My diplomas obtained during my training",
  "formation.step.iut.desc": "Basic computer training (C/C++, PHP, JS, HTML, CSS, SQL, Network, etc.).",
  "formation.step.iut.level": "Bac +2 level.",
  "formation.step.iut.place": "IUT Lyon 1 of Bourg-en-Bresse",
  "formation.step.iut.title": "Computer Science DUT",
  "formation.step.miw.desc": "Specialisation in web development.",
  "formation.step.miw.level": "Bac +3 level.",
  "formation.step.miw.major": "Finished top of the class.",
  "formation.step.miw.place": "IUT Aix-Marseille of Gap",
  "formation.step.miw.title": "Professional Bachelor's Degree in Computer Science and the Web",
  "formation.step.sti.desc": "Science and Technology for Industry and Sustainable Development.",
  "formation.step.sti.place": "René Cassin High School of Mâcon",
  "formation.step.sti.title": "STISD Baccalaureate",
  "languages.english": "English",
  "languages.french": "Français",
  "menu.contact": "CONTACT",
  "menu.experiences": "EXPERIENCES",
  "menu.formation": "FORMATION",
  "menu.portfolio": "PORTFOLIO",
  "menu.presentation": "PRESENTATION",
  "menu.skills": "SKILLS",
  "portfolio.desc": "Here is a small overview of some of my projects and a realizations over time",
  "portfolio.item.customer": "Client",
  "portfolio.item.desc": "Description",
  "portfolio.item.techno": "Technologies",
  "portfolio.item.year": "Year",
  "portfolio.project.atelier.desc": "Creation of a dynamic showcase site for Atelier Brézé, a violin maker in the Mâcon region. It is divided into 3 small projects: the showcase site, visible on any device; an API, in order to communicate with the database and finally an \"admin panel\", so that the administrator can interact with his photo albums (adding photos, publications) and also modify his contact information. A project with a lot of work, in short, for a result that reflects the office's image.",
  "portfolio.project.graine_bio.desc": "Creation of a showcase site during the DUT tutored project. Work with a team of 3 people. This was our first \"real project\" with a customer request.",
  "portfolio.project.portfolio.desc": "Creation of my CV site in order to put my knowledge and skills into practice and to give me more e-visibility.",
  "portfolio.see_project": "See the project",
  "presentation.desc": "I'm {fullName}, young Full-Stack Web Developer. Motivated and passionnate about new technologies, I always look to expand my skills in order to propose the best solution to work with you in your project.",
  "presentation.download_cv": "download cv",
  "presentation.hello": "Hi,",
  "presentation.hobbies.lotr": "Read the Lord of the Rings...",
  "presentation.hobbies.lotr_2": "in only a year !",
  "presentation.hobbies.other": "Rock music",
  "presentation.hobbies.other_2": "Movies (especially Star Wars)",
  "presentation.hobbies.other_3": "And lots of other stuff too...",
  "presentation.hobbies.rugby": "Rugby",
  "presentation.hobbies.rugby_2": "AS Mâcon, now at US Cluny",
  "presentation.hobbies.travels": "Travel",
  "presentation.hobbies.travels_2": "Ireland, Spain, England, Italy",
  "presentation.item.age": "age",
  "presentation.item.city": "city",
  "presentation.item.job": "job",
  "presentation.item.location": "Mâcon, Burgundy",
  "presentation.item.name": "name",
  "presentation.item.web_developer": "Web Developer",
  "presentation.item.years_old": "{age} years old",
  "skills.desc": "My professional and pre-professional experiences have and allow me today to be an autonomous and polyvalent developer, of which here is a small overview :",
  "skills.skills.css": "CSS - SASS - LESS",
  "skills.skills.english": "English",
  "skills.skills.git": "Git tools",
  "skills.skills.html": "HTML5",
  "skills.skills.java": "Java",
  "skills.skills.js": "Javascript - React JS",
  "skills.skills.photoshop": "Photoshop",
  "skills.skills.php": "Symfony - PHP",
  "skills.skills.spanish": "Spanish",
  "skills.skills.sql": "MySQL",
  "skills.skills.teamwork": "Teamwork"
};
