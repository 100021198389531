export default {
  "contact.can_contact_me_email": "Vous me pouvez me contacter par email",
  "contact.desc": "Des questions ? Alors n'hésitez pas !",
  "contact.email_copy_in_clipboard": "Email copié dans le presse-papier",
  "contact.error_email_copy_in_clipboard": "L'email n'a pas pu être copier dans le presse-papier",
  "contact.or_find_me": "Ou me trouver sur les réseaux suivants",
  "experiences.desc": "Un parcours junior mais atypique pour arriver où je suis aujourd'hui",
  "experiences.see_website": "Voir le site de l'entreprise",
  "experiences.step.b2l.desc": "Réalisation et développement d'applications web pour la distribution automobile.",
  "experiences.step.b2l.hiring": "Embauche à la suite du stage en juillet 2019.",
  "experiences.step.b2l.internship": "Stage de fin d'année de Licence Professionnelle.",
  "experiences.step.b2l.since": "Depuis {date}",
  "experiences.step.b2l.team": "Intégré dans une équipe d'une dizaine de personnes.",
  "experiences.step.b2l.web_app_dev": "Développeur d'applications web",
  "experiences.step.datakit.desc": "Refonte d'un outil web interne de suivi de client.",
  "experiences.step.datakit.intern_web_dev": "Stagiaire en développement web",
  "experiences.step.datakit.internship": "Stage de fin d'année de DUT.",
  "experiences.step.datakit.work": "Travail en autonomie.",
  "experiences.step.interim.area": "Région Mâconnaise",
  "experiences.step.interim.desc": "Manutentionnaire, vendeur caissier, employé de cave viticole, métallurgie, etc.",
  "experiences.step.interim.title": "Travaux intérimaires",
  "experiences.step.jobeline.desc": "Travaux viticoles",
  "experiences.step.super_u.desc": "Mise en rayon",
  "experiences.summer": "Été",
  "formation.desc": "Mes diplômes obtenus au cours de ma formation",
  "formation.step.iut.desc": "Formation au base de l'informatique (C/C++, PHP, JS, HTML, CSS, SQL, Réseau, etc.).",
  "formation.step.iut.level": "Niveau Bac +2.",
  "formation.step.iut.place": "IUT Lyon 1 de Bourg-en-Bresse",
  "formation.step.iut.title": "DUT Informatique",
  "formation.step.miw.desc": "Spécialisation dans le développement web.",
  "formation.step.miw.level": "Niveau Bac +3.",
  "formation.step.miw.major": "Major de promotion.",
  "formation.step.miw.place": "IUT Aix-Marseille de Gap",
  "formation.step.miw.title": "Licence Professionnelle Métiers de l'Informatique et du Web",
  "formation.step.sti.desc": "Sciences et Technologies de l'Industrie et du Développement Durable.",
  "formation.step.sti.place": "Lycée René Cassin de Mâcon",
  "formation.step.sti.title": "Baccalauréat STI2D",
  "languages.english": "English",
  "languages.french": "Français",
  "menu.contact": "CONTACT",
  "menu.experiences": "EXPERIENCES",
  "menu.formation": "FORMATION",
  "menu.portfolio": "PORTFOLIO",
  "menu.presentation": "PRESENTATION",
  "menu.skills": "COMPETENCES",
  "portfolio.desc": "Voici un petit aperçu de certains de mes projets et réalisations au fil du temps",
  "portfolio.item.customer": "Client",
  "portfolio.item.desc": "Description",
  "portfolio.item.techno": "Technologies",
  "portfolio.item.year": "Année",
  "portfolio.project.atelier.desc": "Réalisation d'un site vitrine dynamique pour l'Atelier Brézé, un luthier de la région mâconnaise. Il se découpe en 3 petits projets : le site vitrine, visible sur n'importe quel support; une API, afin de communiquer avec la base de données et enfin un \"admin panel\", pour que l'administrateur puisse intéragir avec ses albums photos (ajout de photos, de publications) et également modifier ses informations de contact. Un projet avec beaucoup de travaux en somme, pour un rendu à l'image de son entreprise.",
  "portfolio.project.graine_bio.desc": "Réalisation d'un site vitrine lors du projet tuteuré de DUT. Travail avec une équipe constituée de 3 personnes. Cela a été notre premier \"véritable projet\" avec une demande client.",
  "portfolio.project.portfolio.desc": "Création de mon site CV afin de mettre en pratique mes connaissances et compétences et de me donner plus de e-visibilité.",
  "portfolio.see_project": "Voir le projet",
  "presentation.desc": "Je suis {fullName}, jeune Développeur Web Full-Stack. Motivé et passionné par les nouvelles technologies, je cherche toujours à enrichir mes connaissances afin de concevoir la meilleure solution pour mes projets.",
  "presentation.download_cv": "télécharger cv",
  "presentation.hello": "Salut,",
  "presentation.hobbies.lotr": "A lu le Seigneur des Anneaux...",
  "presentation.hobbies.lotr_2": "en seulement 1 an !",
  "presentation.hobbies.other": "Musique rock",
  "presentation.hobbies.other_2": "Cinéma (surtout Star Wars)",
  "presentation.hobbies.other_3": "Et pleins d'autres trucs aussi...",
  "presentation.hobbies.rugby": "Rugby",
  "presentation.hobbies.rugby_2": "AS Mâcon, aujourd'hui à l'US Cluny",
  "presentation.hobbies.travels": "Voyage",
  "presentation.hobbies.travels_2": "Irlande, Espagne, Angleterre, Italie",
  "presentation.item.age": "âge",
  "presentation.item.city": "ville",
  "presentation.item.job": "job",
  "presentation.item.location": "Mâcon, Bourgogne",
  "presentation.item.name": "nom",
  "presentation.item.web_developer": "Développeur Web",
  "presentation.item.years_old": "{age} ans",
  "skills.desc": "Mes expériences professionnelles et pré-professionnelles m'ont et me permettent aujourd'hui d'être un développeur autonome et polyvalent, dont voici un petit aperçu :",
  "skills.skills.css": "CSS - SASS - LESS",
  "skills.skills.english": "Anglais",
  "skills.skills.git": "Outils Git",
  "skills.skills.html": "HTML5",
  "skills.skills.java": "Java",
  "skills.skills.js": "Javascript - React JS",
  "skills.skills.photoshop": "Photoshop",
  "skills.skills.php": "Symfony - PHP",
  "skills.skills.spanish": "Espagnol",
  "skills.skills.sql": "MySQL",
  "skills.skills.teamwork": "Teamwork"
};
