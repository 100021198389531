export const colors = {
  blue: '#2e8cd9',
  darkBlue: '#184a73',
  grey: '#9a9a9a',
  lightGrey: '#e6e6e6',
  white: '#fff'
}

export const font = {
  size: {
    xxs: '0.6471rem', //11px
    xs: '0.7647rem', //13px
    sm: '0.8824rem', //15px
    base: '1rem', //17px
    lg: '1.118rem', //19px
    lgxxs: '1.294rem', //22px
    lgxs: '1.588rem', //27px
    lgsm: '1.824rem', //31px
    lgxl: '2.118rem', //36px
    lgxxl: '2.471rem' //42px
  },
  weight: {
    base: 400,
    bold: 700,
    black: 800
  }
}

export const borderRadius = {
  sm: '6px',
  base: '12px',
  lg: '24px'
}
