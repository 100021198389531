import cv from './pdf/cv.pdf'

const files = {
  cv: { 
    url: cv, 
    name: "CV_Benjamin_ALEXANDRE" 
  }
}

export default files
