import englishFlag from './img/english_flag.svg.png'
import frenchFlag from './img/france_flag.svg.png'
import logo from './img/logo/logo.png'
import logoName from './img/logo/logo_name.png'
import logoJob from './img/logo/logo_job.png'
import photoProfile from './img/benjamin_alexandre.jpg'
import lagrainebiolande from './img/portfolio/lagrainebiolande.png'
import portfolio from './img/portfolio/portfolio.png'
import atelierbreze from './img/portfolio/atelierbreze.png'
import thanksFrDefault from './img/thanks/thanks_fr_default.png'
import thanksEnDefault from './img/thanks/thanks_en_default.png'
import thanksFrSw from './img/thanks/thanks_fr_sw.png'
import thanksEnSw from './img/thanks/thanks_en_sw.png'
import thanksFrHalloween from './img/thanks/thanks_fr_halloween.png'
import thanksEnHalloween from './img/thanks/thanks_en_halloween.png'
import thanksFrChristmas from './img/thanks/thanks_fr_christmas.png'
import thanksEnChristmas from './img/thanks/thanks_en_christmas.png'

const img = {
  englishFlag: {
    url: englishFlag,
    alt: 'English Flag'
  },
  frenchFlag: {
    url: frenchFlag,
    alt: 'French Flag'
  },
  logo: {
    url: logo,
    alt: 'Logo'
  },
  logoName: {
    url: logoName,
    alt: 'Logo Name'
  },
  logoJob: {
    url: logoJob,
    alt: 'Logo Job'
  },
  photoProfile: {
    url: photoProfile,
    alt: 'Presentation Photo'
  },
  lagrainebiolande: {
    url: lagrainebiolande,
    alt: 'La Graine Biolande Website'
  },
  portfolio: {
    url: portfolio,
    alt: 'Portfolio Website'
  },
  atelierbreze: {
    url: atelierbreze,
    alt: 'Atelier Breze'
  },
  thanksFrDefault: {
    url: thanksFrDefault,
    alt: 'Merci Image'
  },
  thanksEnDefault: {
    url: thanksEnDefault,
    alt: 'Thank You Image'
  },
  thanksFrSw: {
    url: thanksFrSw,
    alt: 'Merci Image'
  },
  thanksEnSw: {
    url: thanksEnSw,
    alt: 'Thank You Image'
  },
  thanksFrHalloween: {
    url: thanksFrHalloween,
    alt: 'Merci Image'
  },
  thanksEnHalloween: {
    url: thanksEnHalloween,
    alt: 'Thank You Image'
  },
  thanksFrChristmas: {
    url: thanksFrChristmas,
    alt: 'Merci Image'
  },
  thanksEnChristmas: {
    url: thanksEnChristmas,
    alt: 'Thank You Image'
  }
}

export default img
